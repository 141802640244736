<template>
  <div class="addendum-inform-detail container">
    <!-- 主体 -->
    <div class="main">
      <!-- 标题 -->
      <div class="title">
        <h1>{{form.noticeTitle}}</h1>
        <div class="release-date">
          <span>发布时间：</span>
          <span>{{form.publishTime|filterDate}}</span>
        </div>
      </div>

      <!-- 招标信息 -->
      <div class="tender-info">
        <div class="item">
          <label>标段编号</label>
          <span>{{form.bidNo}}</span>
        </div>
        <div class="item">
          <label>标段名称</label>
          <span>{{form.bidName}}</span>
        </div>
        <div class="item">
          <label>是否电子标</label>
          <span>{{form.electronic|filterElectronicType}}</span>
        </div>
        <div class="item">
          <label>编制单位</label>
          <span>{{form.enterpriseName}}</span>
        </div>
        <div class="item">
          <label>是否变更保证金</label>
          <span>
            {{form.isChangeDeposit == '1' ? '是' : '否'}}
            <!-- <el-switch
              disabled
              v-model="form.isChangeDeposit"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="'1'"
              :inactive-value="'0'"
            ></el-switch> -->
          </span>
        </div>

        <div class="item">
          <label>是否变更时间</label>
          <span>
            {{form.isChangeTime == '1' ? '是' : '否'}}

            <!-- <el-switch
              disabled
              v-model="form.isChangeTime"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="'1'"
              :inactive-value="'0'"
            ></el-switch> -->
          </span>
        </div>
        <!-- <p>原公告时间：</p> -->
        <!-- <div class="item">
          <label>原公告时间</label>
        </div> -->
        <div class="item">
          <label>招标文件获取开始时间</label>
          
          <span v-if="form.isChangeTime == 0">{{form.oldBidDocGetStartTime|filterTime}}</span>
          <span>{{form.bidDocGetStartTime|filterTime}}</span>
        </div>
        <div class="item">
          <label>招标文件获取结束时间</label>
          <span v-if="form.isChangeTime == 0">{{form.oldBidDocGetEndTime|filterTime}}</span>

          <span>{{form.bidDocGetEndTime|filterTime}}</span>
        </div>
        <div class="item">
          <label>投标文件递交截止时间</label>
          <span v-if="form.isChangeTime == 0">{{form.oldTenderDocSubmitEnd|filterTime}}</span>
          <span>{{form.tenderDocSubmitEnd|filterTime}}</span>
        </div>
        <div class="item">
          <label>招标文件提问截止时间</label>
          <span v-if="form.isChangeTime == 0">{{form.oldBidDocAskEndTime |filterTime}}</span>
            <span>{{form.bidDocAskEndTime|filterTime}}</span>
        </div>
        <div class="item">
          <label>招标文件答复截止时间</label>
          <span v-if="form.isChangeTime == 0">{{form.oldBidDocAnswerEndTime|filterTime}}</span>

          <span>{{form.bidDocAnswerEndTime|filterTime}}</span>
        </div>
        <!-- <div class="item">
          <label>招标人</label>
          <span><a>{{'— —'}}</a></span>
        </div> -->
        <div class="item">
          <label>代理机构</label>
          <span><a>{{form.enterpriseName}}</a></span>
        </div>
      </div>

      <!-- 内容 -->
      <div class="content">
        <h2 class="content-title">补遗通知</h2>
        <div class="content-text" v-html="form.addendumNotice"></div>
        <div class="content-file">
          <p>
            <i class="iconfont icon-fujian"></i>
            <span>补遗通知附件：</span>
          </p>
          <template v-if="form.attachFilePath.length > 0">
            <a :href="baseURL+v" target="_blank" 
              v-for="(v,i) in form.attachFilePath" :key="i" class="file_list">
              <span>{{ v.substring( v.lastIndexOf('/')+1 ) }}</span>
              <span>
                <img src="@/assets/images/download/ic_xz@2x.png" />
                <el-button type="text">下载</el-button>
              </span>
            </a>
          </template>
          <a v-else class="file_list" style="justify-content: center;">无附件</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import tradeInfoApi from "@/api/tradeInfo";
import { mapState } from "vuex";

export default {
  async created() {
    // 获取地址栏传过来的参数
    this.query = this.$route.query;
    // 根据参数获取详情
    let { data } = await tradeInfoApi.findTradeDetail(this.query);
    this.form = data;
    this.form.attachFilePath = JSON.parse(this.form.attachFilePath);
  },
  data() {
    return {
      query: { id: "", type: "", isGov: "" },
      form: {
        attachFilePath: []
      }
    };
  },
  computed: {
    ...mapState(["baseURL", "bidderURL"])
  },
  filters: {
    filterElectronicType(val) {
      if (val == 0) return "非电子标";
      if (val == 1) return "电子标";
    },
    filterTime(val) {
      if (val) {
        return moment(val).format("YYYY-MM-DD HH:mm:ss");
      } else {
        return ''
      }
    },
    filterDate (val) {
      return moment(val).format("YYYY-MM-DD");
    }
  }
};
</script>

<style lang="less" scoped>
.addendum-inform-detail {
  // 主体
  .main {
    // 标题
    .title {
      text-align: center;
      padding: 20px 0;
      h1 {
        color: #20201E;
        font-size: 22px;
        margin-bottom: 10px;
        font-weight: bold;
      }
      .release-date {
        color: #999999;
        font-size: 14px;
      }
    }

    // 招标信息
    .tender-info {
      margin-bottom: 20px;
      margin-top: 24px;
      border: 1px solid rgba(236, 236, 241, 1);
      border-right: 0;
      border-bottom: 0;
      display: grid;
      // grid-template-columns: 206px 370px 206px 370px;
      grid-template-columns: repeat(2 50%);
      .item {
        font-size: 16px;
        color: rgba(73, 74, 76, 1);
        display: inline-block;
        line-height: 50px;
        border-bottom: 1px solid rgba(236, 236, 241, 1);
        border-right: 1px solid rgba(236, 236, 241, 1);
        &:first-child {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        &:nth-child(2) {
          grid-column-start: 1;
          grid-column-end: 3;
        }
        label {
          width: 206px;
          display: inline-block;
          border-right: 1px dashed rgba(236, 236, 241, 1);;
          color: #494A4C;
          font-weight: bold;
        }
        span {
          color: #20201E;
          a {
            color: #6C8BC6;
          }
        }
        label, span {
          padding-left: 24px;
        }
      }
    }

    // 内容
    .content {
      margin-bottom: 20px;
      .content-title {
        padding-left: 10px;
        border-left: 5px solid #3660d9;
        margin-bottom: 20px;
      }
      .content-text {
        color: rgba(73, 74, 76, 1);
        font-size: 16px;
        margin-bottom: 20px;
        line-height: 28px;
        /deep/section, /deep/div, /deep/p {
          line-height: 28px !important;
        }
        /deep/div {
          margin-bottom: 5px;
        }
        font-family: PingFang SC-Regular, PingFang SC;
      }
      .content-file {
        line-height: 20px;
        p {
          line-height: 48px;
          background: #ECECF1;
          font-size: 18px;
          font-weight: bold;
          color: rgba(32, 32, 30, 1);
          padding-left: 15px;
          margin-top: 35px;
        }
        .file_list {
          border-bottom: 1px dashed rgba(236, 236, 241, 1);
          line-height: 68px;
          display: flex;
          justify-content: space-between;
          color: rgba(32, 32, 30, 1);
          img {
            width: 24px;
            margin-right: 4px;
          }
        }
        a {
          color: #3660d9;
        }
      }
    }

  }
}
</style>